import React, { useState } from 'react'
import { EffectFade, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

import LaravelLogo from '../../assets/images/LaravelLogo';
import ASPNetLogo from '../../assets/images/ASPNetLogo';
import { East, GitHub } from '@mui/icons-material';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './LearnSwiper.scss';
import { Button } from '@mui/material';
import { BasicButton } from '../button';

const data = [
    {
        id: 0,
        name: "Laravel",
        icon: <LaravelLogo />
    },
    {
        id: 1,
        name: "ASP.NET",
        icon: <ASPNetLogo />
    },
    {
        id: 2,
        name: "GitHub",
        icon: <GitHub />
    }
]

export default function LearnSwiper() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className='learn-swiper'>
            <Swiper
                style={{
                '--swiper-navigation-color': '#000',
                '--swiper-pagination-color': '#000',
                }}
                effect='fade'
                loop={true}
                navigation={true}
                thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                modules={[EffectFade, FreeMode, Navigation, Thumbs]}
                className="swiper-slide"
            >
                {data.map((lang) => (
                    <SwiperSlide className='slide' key={lang.id}>
                        <div>
                            <h3>Aprende {lang.name}</h3>
                            <BasicButton 
                                icon={lang.icon}
                                title='Aprender'
                                rightIcon={<East />}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={16}
                slidesPerView={3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-thumbs"
            >
                {data.map((lang) => (
                    <SwiperSlide className='thumb' key={lang.id}>
                        {lang.icon}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
