import React, { useContext, useEffect } from 'react'
import { Section } from '../../components'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { LazyImage } from '../../components/lazy'
import LaravelLogo from '../../assets/images/LaravelLogo'
import ASPNetLogo from '../../assets/images/ASPNetLogo'
import { GitHub } from '@mui/icons-material'
import { ColorsContext } from '../../context/Colors'

const categorys = [
    {
        name: "Laravel",
        description: "Texto de descripción para Laravel.",
        id: 'laravel',
        logo: <LaravelLogo />
    },
    {
        name: "ASP.NET",
        description: "Texto de descripción para ASP.NET.",
        id: 'asp-net',
        logo: <ASPNetLogo />
    },
    {
        name: "GitHub",
        description: "Texto de descripción para Git Hub",
        id: "git-hub",
        logo: <GitHub />
    }
]

export default function Aprende() {
    const { resetToDefault } = useContext(ColorsContext);

    useEffect(() => {
        resetToDefault();
    }, [])
    return (
        <>
            <title>Aprende - andrescortes.dev</title>
            <div className="section first"></div>
            <Section id="aprende" className={'aprende-area'}>
                <div className="container">
                    <div className="home-section">
                        <div className="section-title align-center">
                            <h2>Aprende</h2>
                        </div>
                        <div className="section-body">
                            {categorys.map((cat) => (
                                <Button 
                                    className={`button work-category ${cat.id === 'laravel' && 'special'} ${cat.id}`}
                                    key={cat.id}
                                    LinkComponent={Link}
                                    to={`/aprende/${cat.id}`}
                                >
                                    <div className={`cover`}>
                                        {cat.logo}
                                    </div>
                                    <div className="body">
                                        <div className="text">
                                            <h3>{cat.name}</h3>
                                            <span>{cat.description}</span>
                                        </div>
                                        {cat.id === 'laravel' &&
                                        <div className="buttons">
                                            <div className="button variant-outlined">
                                                <div className="title">Saber más</div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </Section>
        </>
    )
}
