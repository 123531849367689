import React from 'react';
import './Footer.scss';
import Name from '../Name';

export default function Footer() {
  return (
    <div className='footer'>
        <footer>
          <div className="logo">
            <Name blank />
          </div>
            <p>Copyright @2024, AndresCortesDev Todos los derechos reservados.</p>
        </footer>
    </div>
  )
}
