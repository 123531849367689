import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { WorksByCategory } from '../../components/sections/Works';
import { ColorsContext } from '../../context/Colors';

export default function W() {
    const params = useParams();
    const { handleHeaderColor } = useContext(ColorsContext);

    useEffect(() => {
        if (params.topic === 'laravel') {
            handleHeaderColor('#ef3e30')
        }
    }, [params.topic])

    console.log(params.topic)

    return (
        <div
            style={{
                paddingTop: '4rem'
            }}
        >
            <title>Aprende - andrescortes.dev</title>
            <WorksByCategory/>
        </div>
    )
}
