import React, { useEffect, useState } from 'react'
import Section from '../section'
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services';
import { BasicButton } from '../button';
import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NorthEastRounded } from '@mui/icons-material';
import { Modal, useModalManager } from '../modal';
import { LazyImage } from '../lazy';

const projectsCategory = [
    {
        id: "all",
        title: "Todos"
    },
    {
        id: "design",
        title: "Diseño"
    },
    {
        id: "game",
        title: "Juego"
    },
    {
        id: "branding",
        title: "Marca"
    },
    {
        id: "marketing",
        title: "Marketing"
    }
]

export const WorksByCategory = ({ close }) => {
    const location = useLocation();
    const [ projects, setProjects ] = useState([]);
    const [ projectsFiltered, setProjectsFiltered ] = useState([]);
    const [ activeCategory, setActiveCategory ] = useState('all');
    const [ fadingOut, setFadingOut ] = useState(false);

    const [ loading, setLoading ] = useState(true);

    
    const filterProjects = (event, newValue) => {
        setFadingOut(true);
        setActiveCategory(newValue);
        setTimeout(() => {
            if (newValue === 'all') {
                setProjectsFiltered(projects);
            } else {
                const filteredProjects = projects.filter(project => project.category === newValue); // Cambia aquí
                setProjectsFiltered(filteredProjects);
            }
            setFadingOut(false);
        }, 200);
    }

    useEffect(() => {
        async function getData() {
            const workRef = doc(firestore, `works/list`);
            const workSnap = await getDoc(workRef);

            if (workSnap.exists()) {
                const data = workSnap.data().data;
                setProjects(data);
                setProjectsFiltered(data);
                setLoading(false);
                console.log(11)
            }
        }

        if (projects.length > 0) {
            return;
        }

        getData();
    }, [loading])

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            setProjects([]);
            setProjectsFiltered([]);
            setActiveCategory('all');
        }
    }, [location.pathname])

    return (
        <div className='flex flow-column' style={{ width: '100%'}}>
            <div 
                className='flex gap-5 border-bottom tabs-header' 
                style={{ 
                    marginBottom: '1rem',
                    position: 'sticky',
                    top: '5rem',
                    zIndex: 99
                }}
            >
                <div style={{
                    maxWidth: 900,
                    width: '100%',
                    margin: '0 auto',
                }}>
                    <Tabs
                        value={activeCategory}
                        onChange={filterProjects}
                        centered
                        className='tabs'
                    >
                        {projectsCategory.map((doc) => (
                            <Tab key={doc.id} label={doc.title} value={doc.id} />
                        ))}
                    </Tabs>
                </div>
            </div>
            <div className='margin-top-5' style={{ minHeight: 800, maxWidth: 900, width: '100%', margin: '0 auto'}}>
                {loading ?
                    <div className='flex justify-center align-center' style={{ height: 200, fontSize: '2rem' }}>
                        <CircularProgress className='progress circular' />
                    </div>
                    :
                    <div className={`projects-wrapper ${fadingOut ? 'fade-out' : ''}`} style={{ padding: '.5rem' }}>
                        {projectsFiltered.map((project) => (
                            <Link onClick={close} key={project.id} className={`project-card`} to={`/curso/${project.id}`}>
                                <div className="cover">
                                    <LazyImage 
                                        src={project.image}
                                    />
                                    <div className="backdrop">
                                        <div className="icon">
                                            <NorthEastRounded />
                                        </div>
                                    </div>
                                </div>
                                <div className="body">
                                    <span>{project.category}</span>
                                    <h3>{project.title}</h3>
                                </div>
                            </Link>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default function WorksSection({ className }) {
    const { openModal, closeModal } = useModalManager();

    const categorys = [
        {
            name: "Laravel",
            id: 1,
            logo: "https://media.licdn.com/dms/image/v2/D4E12AQEhSWRTreXYew/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1694479065771?e=2147483647&v=beta&t=0AspTC8wC-hiRAaORRGWn1gnw2RHbenMsmbAHZTBHqU"
        },
        {
            name: "ASP.NET",
            id: 2,
            logo: "https://runcode-app-public.s3.amazonaws.com/images/aspnet-compiler.original.png"
        }
    ]

    const handleOpen = (cat) => {
        openModal(`work-${cat.id}`, (
            <Modal
                title={cat.name}
                close={() => closeModal(`work-${cat.id}`)}
            >
                <WorksByCategory />
            </Modal>
        ))
    }

    return (
        <Section id="portfolio" className={`projects-area ${className}`}>
            <div className="container">
                <div className="home-section color-base">
                    <div className="section-title align-center">
                        <h2>Trabajos y Proyectos</h2>
                        <p>Check out some of my design projects, meticulously crafted with love and dedication, each one reflecting the passion and soul I poured into every detail.</p>
                    </div>
                    <div className="flex gap-10 margin-top-20">
                        {categorys.map((cat) => (
                            <Button onClick={() => handleOpen(cat)} className='button work-category' key={cat.id}>
                                <div className='cover'>
                                    <LazyImage 
                                        src={cat.logo}
                                        borderRadius={5}
                                        objectFit='contain'
                                    />
                                </div>
                                <span>{cat.name}</span>
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    )
}
